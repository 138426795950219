<template>
  <div class="container">
    <edit-header
      entity="coupon"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :errorMessage="errorMessage"
      :successMessage="successMessage"
      :isNew="isNew">
      <template v-slot:options>
        <log-button-table v-if="item.id && item.modelName" v-model="item"></log-button-table>
      </template>
    </edit-header>
    <form
      v-disable-all="!can(uiPermissions.COUPONS_UPDATE)"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div data-test="row-cards-coupon" class="row row-cards row-deck">
        <div class="col-md-6">
          <div data-test="card-coupon" class="card">
            <div class="card-header">
              <h3 class="card-title">Coupon</h3>
              <div v-if="!isNew" class="card-options">
                <label
                  data-test="toggle-has-been-used"
                  class="custom-switch m-0">
                  <input
                    v-model="item.used"
                    type="checkbox"
                    class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">Has been used</span>
                </label>
              </div>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label class="form-label">Code</label>
                <input
                  v-model="item.code"
                  type="text"
                  data-test="txt-code"
                  class="form-control">
              </div>
              <div class="form-group">
                <label class="form-label">Description (optional)</label>
                <input
                  v-model="item.description"
                  type="text"
                  data-test="txt-description"
                  class="form-control">
              </div>
              <div class="form-row">
                <div class="form-group col-4">
                  <label class="form-label">Category</label>
                  <select
                    v-model="category"
                    data-test="select-category"
                    class="form-control custom-select"
                    required>
                    <option value="one-box">One-Box</option>
                    <option value="multi-box">Multi-Box</option>
                  </select>
                </div>
                <div class="form-group col-4">
                  <label class="form-label">Type</label>
                  <select
                    v-model="item.type"
                    data-test="select-type"
                    class="form-control custom-select"
                    required>
                    <option value="" disabled>Select type</option>
                    <option
                      v-for="(value, key) in CouponTypes"
                      :key="value"
                      :value="value">
                      {{key}}
                    </option>
                  </select>
                </div>
                <template v-if="category === 'multi-box'">
                  <div class="form-group col-4">
                    <label class="form-label">Box Split</label>
                    <select
                      v-model="selectedBoxNumber"
                      data-test="select-box-split"
                      class="form-control custom-select"
                      required>
                      <template v-for="n in maxBoxNumber">
                        <option
                          v-if="n != 1"
                          :key="n"
                          :value="n">
                          {{n}} boxes
                        </option>
                      </template>
                    </select>
                  </div>
                </template>
              </div>
              <template v-if="category === 'multi-box'">
                <div class="form-group">
                  <label class="form-label">Split Amount</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">Box 1 | {{item.type === CouponTypes.Fixed ? 'AED' : '%'}}</span>
                    </div>
                    <input
                      v-model="item.amount"
                      type="number"
                      data-test="txt-split-amount"
                      class="form-control"
                      required
                      min="0"/>
                  </div>
                  <template v-for="n in selectedBoxNumber - 1">
                    <div :key="n" class="input-group mt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          Box {{(n + 1)}} | {{item.type === CouponTypes.Fixed ? 'AED' : '%'}}
                        </span>
                      </div>
                      <input
                        v-model="item.couponConfigurations[n - 1]"
                        type="number"
                        data-test="txt-split-amount"
                        class="form-control"
                        required
                        min="0"/>
                    </div>
                  </template>
                </div>
              </template>
              <template v-else>
                <div class="form-group">
                  <label class="form-label">Amount</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">{{item.type === CouponTypes.Fixed ? 'AED' : '%'}}</span>
                    </div>
                    <input
                      v-model="item.amount"
                      type="number"
                      data-test="txt-amount"
                      class="form-control"
                      required
                      min="0"/>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div data-test="card-limits" class="card">
            <div class="card-header">
              <h3 class="card-title">Limits (optional)</h3>
              <div class="card-options">
                <label
                  data-test="toggle-first-time-users"
                  class="custom-switch m-0">
                  <input
                    v-model="item.for_new_users"
                    type="checkbox"
                    class="custom-switch-input">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">First time users only</span>
                </label>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group col-6">
                  <label class="form-label">Usage limit</label>
                  <input
                    v-model="item.usageLimit"
                    type="number"
                    data-test="txt-usage-limit"
                    class="form-control"
                    required
                    min="1"/>
                </div>
                <div class="form-group col-6">
                  <label class="form-label">Usage limit per user</label>
                  <input
                    v-model="item.usageLimitPerUser"
                    type="number"
                    data-test="txt-usage-limit-per-user"
                    class="form-control"
                    required
                    min="1"/>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-6">
                  <label class="form-label">Min. spend limit</label>
                  <input
                    v-model="item.minSpendLimit"
                    type="number"
                    data-test="txt-min-spend-limit"
                    class="form-control"
                    min="0"/>
                </div>
                <div class="form-group col-6">
                  <label class="form-label">Max. spend limit</label>
                  <input
                    v-model="item.maxSpendLimit"
                    type="number"
                    data-test="txt-max-spend-limit"
                    class="form-control"
                    min="0"/>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col">
                  <label class="form-label">Expires at</label>
                  <div class="input-group">
                    <input
                      v-model="expiry"
                      type="date"
                      data-test="txt-expires-at"
                      class="form-control">
                    <div class="input-group-append">
                      <span class="input-group-text">{{moment(expiry).format('ddd')}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!isNew && can(uiPermissions.COUPONS_CUSTOMERS_UPLOAD)"
              class="card-footer">
              <div class="form-row">
                <div class="form-group col">
                  <label class="form-label text-dark">Customers</label>
                  <div class="input-group">
                    <div class="flex-1 position-relative">
                      <input
                        ref="fileInput"
                        type="file"
                        accept=".csv"
                        class="form-control p-1"
                        data-test="file-upload"
                        :disabled="uploading"
                        @change="handleFileChange"
                        @input="clearSelectedFile($event)"/>
                      <i
                        v-if="selectedFile"
                        class="fe fe-x clearable"
                        @click="clearSelectedFile($event, true)">
                      </i>
                    </div>
                    <div class="input-group-append">
                      <button
                        class="btn btn-primary"
                        type="button"
                        ignore-disable
                        force-disable
                        :disabled="!selectedFile || uploading"
                        @click="onUpload">
                        <i v-if="uploading" class="fe fe-loader spin-icon"></i>
                        Upload
                      </button>
                    </div>
                  </div>
                  <small class="text-muted">Only upload CSV file with 2 columns (Customer Id, Assign) and assign column should be Yes or No.</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="coupon"
            :isNew="isNew"
            :submitting="submitting"
            @delete="handleDelete"></edit-footer>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import LogButtonTable from '@/components/LogButtonTable';
import edit from '@/mixins/edit';
import uploader from '@/mixins/uploader';
import {coupons} from '@/services';
import CouponTypes from '@hellochef/shared-js/enums/CouponTypes';
import moment from 'moment-timezone';
import {getCardinalNumber} from '@/utils';

export default {
  components: {
    EditFooter,
    EditHeader,
    LogButtonTable,
  },
  mixins: [
    edit,
    uploader,
  ],
  data() {
    return {
      getCardinalNumber,
      CouponTypes,
      maxBoxNumber: 9,
      selectedBoxNumber: 2,
      category: 'one-box',
      item: {
        amount: 1,
        code: '',
        description: '',
        expiry: null,
        maxSpendLimit: null,
        minSpendLimit: null,
        modelName: '',
        type: CouponTypes.Fixed,
        usageLimit: 1,
        usageLimitPerUser: 1,
        used: false,
        couponConfigurations: [],
        for_new_users: false,
      },
      uploading: false,
      selectedFile: null,
    };
  },
  computed: {
    expiry: {
      get() { return this.item.expiry ? this.item.expiry.format('YYYY-MM-DD') : ''; },
      set(newValue) { this.item.expiry = newValue ? moment(newValue) : null; },
    },
    route() {
      return `/coupons/${this.item.id}`;
    },
  },
  methods: {
    fetchData(id) {
      return coupons.getById(id);
    },
    async handleDelete() {
      if (window.confirm('Deleting a coupon cannot be undone. Are you sure that you want to delete this coupon?')) {
        await coupons.deleteById(this.item.id);
        this.$router.push('/coupons');
      }
    },
    submitData(item) {
      if (this.category === 'one-box') {
        item.couponConfigurations = [];
      }
      else if (item.couponConfigurations.length > this.selectedBoxNumber - 1) {
        item.couponConfigurations = item.couponConfigurations.slice(0, this.selectedBoxNumber - 1);
      }

      const payload = Object.assign({}, item, {

        amount: Number(item.amount),
        expiry: item.expiry ? item.expiry.format('YYYY-MM-DD') : null,
        maxSpendLimit: item.maxSpendLimit !== null ? Number(item.maxSpendLimit) : null,
        minSpendLimit: item.minSpendLimit !== null ? Number(item.minSpendLimit) : null,
        usageLimit: Number(item.usageLimit),
        usageLimitPerUser: Number(item.usageLimitPerUser),
      });

      return coupons.saveOrUpdate(payload);
    },
    transformData(result) {
      if (result.item.expiry) { result.item.expiry = moment(result.item.expiry); }

      this.selectedBoxNumber = result.item.couponConfigurations.length > 0 ? result.item.couponConfigurations.length + 1 : 2;
      this.category = result.item.couponConfigurations.length > 0 ? 'multi-box' : 'one-box';

      return result;
    },
    async onUpload() {
      await this.handleUpload(() => coupons.uploadFile(this.selectedFile, {
        path: `/${this.$route.params.id}/customers`,
      }));
    },
  },
};
</script>

<style scoped>
.clearable {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}
</style>
