import {captureException} from '@sentry/vue';

export default {
  methods: {
    handleFileChange(e) {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];

        if (file.size > 10 * 1000000) { // 10 MB
          this.$toasted.error('File too large. Files cannot exceed 10 MB.');
          return;
        }

        this.selectedFile = file;
      }
    },
    clearSelectedFile(event, confirm = false) {
      if (event?.target?.value || (confirm && !window.confirm('Are you sure that you want to remove the file?'))) {
        return;
      }

      this.$refs.fileInput.value = null;
      this.selectedFile = null;
    },
    async handleUpload(uploadService) {
      this.resetData();

      if (!this.selectedFile) {
        this.$toasted.error('Please select a file to upload.');
      }

      try {
        this.uploading = true;
        const {data} = await uploadService();
        this.submitted = true;
        this.successMessage = data.message;
      }
      catch (error) {
        captureException(error);
        this.error = true;
        this.errorMessage = 'An error occurred while uploading the file. Please try again.';

        if (error.response?.status === 422 && error.response?.data.message) {
          this.errorMessage = error.response.data.message;
        }
      }
      finally {
        this.uploading = false;
        this.clearSelectedFile();
      }
    },
  },
};
